import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../components/Layout"
import Button from '../components/buttons/button'
import ProductBanner from "../components/product_banners/product_banners";
import Faq from "../components/faq/faq";
import { SalWrapper } from "react-sal";
import Banner from "../imgs/bg_cambia_forma.jpg";
import IframeVIdeo from "../components/iframe_video/iframe_video";
import { graphql, Link, useStaticQuery } from "gatsby";
import Image from "../components/image/image";
/*import "../pages/pages.css";*/

import "./ahorrar.css";
import faqjson from "../misc/ahorrar_related/faq.json";
import { apiRequest } from "../apiRquest/apiRequest";
import InvertirImg from '../imgs/banners-ballanz-02.jpg'
import { Helmet } from "react-helmet";

let text = `Conocé lo que tenemos para vos, <br/> <span class='highlightsbold'> convertite en inversor.</span>`;
const pageTitle = 'Abrí tu cuenta en Balanz y empezá a invertir';
const pageDesc = 'Abrí tu cuenta de inversión en Balanz, online y sin costo de mantenimiento. Comenzá a invertir online en Bonos, Acciones, Fondos de Inversión y Cedears.';
const AhorrarPage = () => {


    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 13}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });


    useEffect(() => {
        apiRequest
            .get("/v1/faq/13")
            .then((res) => {
                const data = []
                const questions = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })
                setFaq((old) => ({...old, list: data}));

            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
    const page = (
        <>
            <Helmet>

                <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "VideoObject",
                            "name": "Conocé los  pasos para abrir tu cuenta",
                            "description": "Con tu cuenta Balanz tenés acceso a invertir mejor, créala en 5 minutos con este video explicativo.",
                            "thumbnailUrl": "https://www.youtube.com/watch?v=YTG86tmmJFw",
                            "uploadDate": "2022-07-08",
                            "duration": "PT0M55S",
                            "contentUrl": "https://balanz.com/abrir-cuenta/",
                            "embedUrl": "https://balanz.com/abrir-cuenta/"
                        }
                    `}
                </script>

            </Helmet>
            <ProductBanner
                bannerContainerClassName="bannerAhorrar"
                bannerTrasparecy="bannerMask"
                productTitle="Cambiá tu forma de&nbsp;ahorrar"
                bannerImage={Banner}
                bannerImageClassName="cedearsBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <SalWrapper>
            <section className="fullwidth apertura-online">
                <Container>
                    <Row className="py-5">
                        <h2 className="mt-2"
                            data-sal="fade"
                            data-sal-delay="0"
                            data-sal-duration="200"
                        ><span className="smaller-title">Apertura 100% online en pocos minutos</span></h2>
                        <div className="p-3"
                             data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="200"
                        >
                            <Button id="abri_tu_cuenta_1" variant="secondary" text="Abrir cuenta"
                                    onClick={() => { window.open("https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1381", "_blank") }} />
                        </div>
                        <ul className="triangle-list list-text mt-md-5 mt-3">
                            <li data-sal="slide-up">Cuenta sin costo de apertura, ni mantenimiento.</li>
                            <li data-sal="slide-up" data-sal-delay="100">La mejor tecnología.</li>
                            <li data-sal="slide-up" data-sal-delay="100">Plataformas Web y App, seguras e intuitivas.</li>
                            <li data-sal="slide-up" data-sal-delay="100">Más de 20 años de trayectoria y experiencia.</li>
                            <li data-sal="slide-up" data-sal-delay="100">Acceso a todos los activos del mercado local y del mundo.</li>
                            <li data-sal="slide-up" data-sal-delay="100">Atención personalizada de nuestros asesores financieros.</li>
                            <li data-sal="slide-up" data-sal-delay="100">Capital administrado por nuestros especialistas en inversiones.</li>
                            <li data-sal="slide-up" data-sal-delay="100">Recomendaciones a medida para inversiones en pesos o dólares.</li>
                        </ul>
                    </Row>
                </Container>
            </section>
            <section className="fullwidthbg inversor"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container className="d-flex align-items-stretch">
                    <Row className="d-flex justify-content-start align-items-center flex-grow-1 flex-shrink-1">
                        <Col xs={12} md={9}>
                            <div className="section-content">
                                <h2 className="titulo"
                                    data-sal="slide-up"
                                    data-sal-delay="200"
                                    data-sal-duration="200"
                                ><span className="tit-60">Manejá tus inversiones de <span className="highlightsbold">forma simple, segura y online</span></span></h2>
                            <div className=" d-md-block cta mt-3 mb-2"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                            >
                            <Button id="manejar-inversiones-button" variant="secondary" text="Abrir Cuenta" onClick={() => { window.open("https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1381", "_blank") }} />
                        </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth abrir-cuenta"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container>
                    <Row className="gx-5 py-3 py-md-4 py-lg-5 justify-content-between">
                        <Col xs={12} md={5} className="d-flex align-items-center justify-content-center">
                            <h2 className="mx-0 mb-5 my-md-0"><span className="bigger-title">Conocé los <br className="d-none d-md-inline" /> pasos para abrir tu cuenta</span> </h2>
                        </Col>
                        <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                            <IframeVIdeo
                                src="https://www.youtube.com/watch?v=YTG86tmmJFw"
                                mobile={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth appdownload overlap lightgrey bg-lt-grey pb-0"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container>
                    <Row className="d-flex align-items-start justify-content-start">
                        <Col xs={12} sm={8}>
                            <div className="section-content pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                                <p className="volanta"
                                   data-sal="slide-up"
                                   data-sal-delay="100"
                                   data-sal-duration="200"
                                >Descargá la APP</p>
                                <h3 className="titulo"
                                    data-sal="slide-up"
                                    data-sal-delay="100"
                                    data-sal-duration="200"
                                >Abrí tu cuenta desde tu celular</h3>
                                <div className="cta"
                                     data-sal="fade"
                                     data-sal-delay="200"
                                     data-sal-duration="200"
                                > <a href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&utm_source=Website&utm_medium=Banner&utm_campaign=Android" target="_blank">
                                    <Image
                                        className="store-img"
                                        src="PlayStore.png"
                                        alt="GStore"
                                    /></a>
                                    <a href="https://apps.apple.com/us/app/balanz/id1318206099?utm_source=Website&utm_medium=Banner&utm_campaign=IOS" target="_blank">
                                    <Image
                                        className="store-img"
                                        src="AppStore.png"
                                        alt="AStore"
                                    />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className="fullwidthbg part-blue lightgrey pt-2 pb-5"
                     data-sal="fade"
                     data-sal-delay="0"
                     data-sal-duration="200"
            >
                <Container>
                    <Row>
                        <Col xs={12} md={7} lg={7}  className="column col-text">
                            <div className="section-content pt-sm-5 pt-4">
                                <h3 className="titulo"
                                    data-sal="slide-up"
                                    data-sal-delay="100"
                                    data-sal-duration="200"
                                >¿Cómo empezar a invertir?</h3>
                                <p className="texto"
                                   data-sal="slide-up"
                                   data-sal-delay="100"
                                   data-sal-duration="200"
                                >Operá todos los instrumentos en la plataforma transaccional más completa del mercado.</p>
                                <div className="cta py-3">
                                    <Link to="/empezar-a-invertir">
                                    <Button variant="nofill-2" text="Ver más" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={5} className="column col-img">
                            <div className="section-content"
                                 data-sal="slide-up"
                                 data-sal-delay="300"
                                 data-sal-duration="200"
                            >
                                <img
                                    className="section-image lift ratio ratio-1x1"
                                    src={InvertirImg}
                                    alt="Cómo empezar a invertir"
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className="fullwidth contactanos">
                <Container>
                    <Row className="d-flex justify-content-center g-0">
                        <Col xs={11}>
                            <p className="pt-5 pb-4 base-text-2 text-center"
                               data-sal="slide-up"
                               data-sal-delay="0"
                               data-sal-duration="200"
                            >Si todavía tenés dudas, podés escribirnos.</p>
                            <div className="d-flex justify-content-center p-2"
                                 data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="200"
                            >
                                <Link to="/contacto">
                                <Button variant="secondary" text="Contactanos"/>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth preguntas-frecuentes">
                <Container>
                    <Row>
                        <Faq faq={faq} customClass={'ahorrar-faq'} />
                    </Row>
                </Container>
            </section>
            </SalWrapper>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"abrir-cuenta"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default AhorrarPage;